// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-dark: #0c121c;
$color-white: #fff;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #005693;
$color-colored: #005a2b;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.term {
  display: inline;

  :global {
    .aui-checkbox__text {
      font-size: 13px;
      margin-left: 5px;
    }

    .aui-Field-field {
      display: inline;
    }
  }
}

.termLink {
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  display: inline;
}

@keyframes showCollapseIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.termText {
  margin-top: 10px;
  color: $color-text-gray;
  font-size: 13px;
  animation: showCollapseIn 300ms;
}
