// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-dark: #0c121c;
$color-white: #fff;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #005693;
$color-colored: #005a2b;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.tabTitles {
  :global {
    .aui-tabTitle {
      font-family: $font-family-GeometriaBold;
      font-size: 21px;
      color: #929699;
      background: none;
      border: none;
      cursor: pointer;
      line-height: 1.1;

      &:first-child {
        margin-right: 27px;
      }

      :local(.bookTabTitle) {
        color: lighten($color-colored, 13%);
      }
    }

    .aui-tabTitle-active {
      color: #1a212d;

      :local(.bookTabTitle) {
        color: $color-colored;
      }
    }
  }

  @media (min-width: $media-lg) {
    :global {
      .aui-tabTitle {
        font-size: 30px;

        &:first-child {
          margin-right: 40px;
        }
      }
    }
  }
}

.tabContents {
  display: none;

  &:global(.show) {
    display: block;
    margin-top: 35px;
  }
}

.bookTabTitle {
  border: none;
  background: none;
  font-size: 21px;
  font-family: $font-family-GeometriaBold;
  color: $color-blue;
  cursor: pointer;

  @media (min-width: $media-lg) {
    font-size: 30px;
  }
}

.btnToggle {
  @extend %bus-instock-control-reset;
  position: relative;
  padding-right: 20px;
  font-family: $font-family-GeometriaBold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.8px;
  color: $color-dark;
  cursor: pointer;

  &:hover {
    color: $color-blue;
    &:after {
      border-color: $color-blue;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -3px;
    right: 0;
    width: 10px;
    height: 10px;
    border-left: 2px solid $color-dark;
    border-bottom: 2px solid $color-dark;
    transform: rotate(-45deg);
    pointer-events: none;
    transition: color 0.3s ease;
  }

  &:global(.open) {
    &:after {
      top: 3px;
      transform: rotate(135deg);
    }
  }
}
