// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-dark: #0c121c;
$color-white: #fff;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #005693;
$color-colored: #005a2b;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  margin-bottom: 30px;

  h1 {
    margin-bottom: 0;
  }
}

.favourites {
  width: 100%;
  margin-bottom: 20px;
}

.sort {
  width: 100%;
  margin-bottom: 30px;
  font-family: $font-family-GeometriaBold;
  text-transform: uppercase;
  font-size: 11px;

  :global {
    .azgazui__cars-sort {
      margin-top: 0;
    }

    .azgazui__cars-sort-label {
      color: #929699;
    }
  }
}

.back {
  margin-bottom: 20px;
}

@media (min-width: $media-xl) {
  .head {
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .favourites {
    width: auto;
    margin-bottom: 0;
  }

  .sort {
    width: auto;
    margin-bottom: 0;
  }
}
