// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-dark: #0c121c;
$color-white: #fff;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #005693;
$color-colored: #005a2b;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.wrap {
  padding-bottom: 20px;
  background-color: $color-gray-lighter2;
}

.barList {
  display: flex;
  justify-content: flex-end;
}

.link {
  display: inline-flex;
  color: $color-text-gray;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;

  &:hover,
  &:active {
    color: $color-text-gray;
  }
}

.value {
  margin-left: 3px;
  color: $color-blue;
  font-family: $font-family-GeometriaBold;
}

@media (min-width: $media-lg) {
  .wrap {
    position: relative;
    top: -45px;
    padding-bottom: 0;
  }

  .barList {
    height: 0;
  }
}
