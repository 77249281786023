.aui-checkbox__icon {
  border-radius: 4px;
  border-width: 1px;
  background: $color-white;
  width: 22px;
  height: 22px;

  &:before {
    top: 5.5px;
    left: 4.5px;
    width: 12px;
    height: 6px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    transition: none;
  }
}

.aui-checkbox__input:checked ~ .aui-checkbox__icon {
  background-color: $color-blue;
  border-color: $color-blue;

  &:before {
    border-bottom-color: $color-white;
    border-left-color: $color-white;
  }
}

.aui-checkbox__input:disabled ~ .aui-checkbox__icon {
  background-color: $color-gray;
  border-color: $color-gray;

  &:before {
    border-bottom-color: $color-white;
    border-left-color: $color-white;
  }
}

.aui-checkbox__input:focus ~ .aui-checkbox__icon {
  border-color: $color-blue;
}
