// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-dark: #0c121c;
$color-white: #fff;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #005693;
$color-colored: #005a2b;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.popover {
  :global {
    .ant-popover-content {
      position: relative;
    }
    .ant-popover-inner {
      border: 1px solid $color-blue;
    }

    .ant-popover-inner-content {
      padding: 1px 8px;
      font-size: 13px;
    }

    .ant-popover-arrow {
      box-shadow: -1px -1px 0px $color-blue !important;
    }

    a {
      color: $color-dark;
      text-decoration: none;
      font-family: $base-font;

      &:hover {
        color: $color-blue;
      }
    }
  }

  &:global(.ant-popover-placement-bottomLeft) {
    :global {
      .ant-popover-content {
        top: -7px;
      }
      .ant-popover-arrow {
        top: -2px !important;
        box-shadow: -1px -1px 0px $color-blue !important;
      }
    }
  }
  &:global(.ant-popover-placement-top) {
    :global {
      .ant-popover-content {
        top: 5px;
      }

      .ant-popover-arrow {
        bottom: -3px !important;
        box-shadow: 1px 1px 0px 0px #005693 !important;
      }
    }
  }
}

.popoverActive {
  :global {
    .ant-popover-arrow {
      border-color: $color-blue !important;
    }

    .ant-popover-inner-content {
      background-color: $color-blue;
      color: $color-gray-lighter2;
    }
  }
}
